<template>
  <div
    style="max-height: 79vh; overflow: auto;"
    class="pt-0 vitrify centraliza expande-horizontal wrap"
  >
    <div class="expande-horizontal centraliza wrap pt-3 fonte">
      <v-card
        hover
        style="border-radius: 12px !important;"
        color="#f7f7f7"
        class="pa-3"
        width="300"
      >
        <div class="expande-horizontal centraliza wrap">
          <v-flex xs12>
            <div class="expande-horizontal centraliza">
              <img
                style="z-index: 300;"
                @click.prevent="openUploadProfileContainer"
                class="store-profile"
                :src="
                  getLoggedUser.profile_image ||
                    'https://via.placeholder.com/512x512'
                "
              />
              <div>
                <input
                  id="image_profile"
                  type="file"
                  ref="profile_image"
                  @change="preUploadProductImage"
                />
              </div>
            </div>
          </v-flex>
          <v-flex xs12>
            <div class="expande-horizontal centraliza">
              <div
                v-if="
                  profile_image_progress !== '' && profile_image_progress < 100
                "
                style="width: 200px;"
              >
                <v-progress-linear
                  :color="$theme.primary"
                  height="3"
                  class="mt-2"
                  style="border-radius: 6px;"
                  :value="profile_image_progress"
                  striped
                ></v-progress-linear>
              </div>
            </div>
          </v-flex>
          <v-flex xs12>
            <div class="expande-horizontal centraliza">
              <span class="fonteMini grey--text">foto de perfil</span>
            </div>
          </v-flex>
          <v-flex xs12>
            <div class="expande-horizontal my-3 centraliza">
              <v-divider></v-divider>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-list dense color="transparent" class="pa-0 ma-0 mt-2">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    Nome
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    getLoggedUser.nome
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    CPF
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    getLoggedUser.cpf
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    Email
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    getLoggedUser.email
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    Telefone
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    getLoggedUser.telefone
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-flex>
        </div>
      </v-card>
    </div>
    <v-dialog
      fullscreen
      transition="slide-x-transition"
      v-model="cropProfileImage"
    >
      <v-card
        dark
        class="expande-horizontal wrap centraliza"
        style="min-height: 100vh;max-height: 100vh;"
      >
        <v-flex xs12>
          <v-list dense class="pa-0 ma-0 fonte">
            <v-list-item class="pr-6" @click="cropProfileImage = false">
              <v-avatar size="31" class="mr-1" :color="$theme.primary" icon>
                <v-icon color="#333">mdi-arrow-left</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  Ajuste a imagem
                </v-list-item-title>
                <v-list-item-subtitle
                  >será considerado apenas o que estiver
                  selecionado</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  @click="uploadProductImage"
                  :color="$theme.primary"
                  dark
                  rounded
                  class="black--text"
                  >Salvar</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-flex>
        <div class="expande-horizontal wrap">
          <v-flex xs12>
            <div style="width: 100%; height: 91vh;">
              <vue-cropper
                ref="cropper20"
                :img="cropProfileImageData"
                :output-size="option2.size"
                :output-type="option2.outputType"
                :info="true"
                :full="option2.full"
                :fixed="fixed"
                :fixed-number="fixedNumber"
                :can-move="option2.canMove"
                :can-move-box="option2.canMoveBox"
                :fixed-box="option2.fixedBox"
                :original="option2.original"
                :auto-crop="option2.autoCrop"
                :auto-crop-width="option2.autoCropWidth"
                :auto-crop-height="option2.autoCropHeight"
                :center-box="option2.centerBox"
                @real-time="realTime"
                :high="option2.high"
                @img-load="imgLoad"
                mode="cover"
                :max-img-size="option2.max"
                @crop-moving="cropMoving"
                @crop-complete="uploadProductImage"
              ></vue-cropper>
            </div>
          </v-flex>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getStorage, ref, getDownloadURL, uploadBytesResumable, deleteObject  } from "firebase/storage";

export default {
  data() {
    return {
      profile_image_progress: "",
      option2: {
        size: 256,
        full: true,
        outputType: "png",
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 256,
        autoCropHeight: 256,
        centerBox: true,
        high: false,
        max: 256
      },
      cropProfileImageData: "",
      cropProfileImage: false,
      fixed: true,
      fixedNumber: [256, 256],
      opened: false
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser", "get_funcionario"])
  },
  methods: {
    ...mapActions(["atualizar_funcionario"]),
    save() {
      this.atualizar_funcionario({
        ...this.getLoggedUser,
        from_profile: true
      });
    },
    openUploadProfileContainer() {
      this.$refs.profile_image.click();
    },
    cropMoving() {},
    cropImg() {},
    imgLoad() {},
    realTime() {},
    preUploadProductImage() {
      const input = this.$refs.profile_image;
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          this.cropProfileImageData = e.target.result;
          this.cropProfileImage = true;
        };
      };
      reader.readAsDataURL(file);
    },
    async uploadProductImage() {
      this.$refs.cropper20.getCropBlob(async data => {
        var metadata = {
          contentType: data.type
        };
        const storage = getStorage();
        const storageRef = ref(storage);
        const imageRef = ref(
          storageRef,
          `store_images/${this.getLoggedUser.activeTenant._id}.png`
        );
        const task = uploadBytesResumable(imageRef, data, metadata);
        task.on(
          "state_changed",
          snapshot => {
            this.profile_image_progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.error(error);
          },
          async () => {
            this.getLoggedUser.profile_image = await getDownloadURL(task.snapshot.ref);
            this.save();
            setTimeout(() => {
              this.$forceUpdate(); 
            }, 300);
          }
        );
      });
    }
  },
  created() {
    this.getLoggedUser.senha = undefined;
  }
};
</script>

<style>
.store-profile {
  width: 120px;
  height: 120px;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.store-profile:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
</style>
